import React, { useState, useCallback, createContext, useContext } from "react";
import { executeApi } from "./base-context";
import { LoaderContext } from "./loader-context";
import { NotificationContext } from "./notification-context";
import { MenuAPI } from "../api";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
export const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const navigation = useNavigate();
  const { showLoading, loading } = useContext(LoaderContext);
  const { addMessage } = useContext(NotificationContext);
  const [menuListData, setListData] = useState([]);
  const executeMenuAPI = async (apiFunction, request) => {
    const response = await executeApi(
      apiFunction,
      request,
      showLoading,
      addMessage
    );
    return response;
  };

  const getMenuListData = async (request) => {
    try {
      const res = await executeMenuAPI(MenuAPI.getMenuList, request);
      if (res.succeeded) {
        setListData(res.data);
      } else {
        addMessage("data get Failed");
      }
    } catch (error) {
      addMessage("Failed to fetch Data");
    }
  };
  const createMenuRecord = async (request) => {
    try {
      const response = await executeMenuAPI(MenuAPI.createMenu, request);

      if (response?.succeeded) {
        toast.success("Menu Add successfully");
        addMessage({ message: "Menu Add successfully", type: "success" });
        navigation("/menu");
      } else {
        addMessage({
          message: response?.msg || "Menu Add unsuccessfully",
          type: "error",
        });
        toast.error(response?.msg || "Menu Add unsuccessfully");
      }
    } catch (error) {
      addMessage({ message: error?.msg, type: "error" });
    }
  };

  const updateMenuRecord = async (request) => {
    try {


      const response = await executeMenuAPI(MenuAPI.updateMenu, request);

      if (response?.succeeded) {
        toast.success("Menu Update Successfully");

        addMessage({ message: "Menu Update Successfully", type: "success" });

        navigation("/menu");
      } else {
        addMessage({ message: response?.message, type: "error" });
      }
    } catch (error) {
      addMessage({ message: "Menu update unsuccessfully", type: "error" });
      toast.error("Menu update unsuccessfully");
    }
  };

  const removeMenuRecord = async (id) => {
    try {


      const response = await executeMenuAPI(MenuAPI.deleteMenu, id);
      if (response?.succeeded) {
        toast.success("Menu delete successfully");
        addMessage({ message: "Menu delete successfully", type: "success" });
        let payload = {
          limit: "100",
          offset: "0",

          sorting: {
            fieldName: "id",
            direction: "Asc",
          },
        };
        await getMenuListData(payload);
      }
    } catch (error) {
      toast.error("Menu delete unsuccessfully");
      addMessage({ message: "Menu delete unsuccessfully", type: "error" });
    }
  };

  const contextValue = {
    menuListData,
    loading,
    getMenuListData: useCallback((request) => {
      getMenuListData(request);
    }, []),
    createMenuRecord: useCallback((request) => {
      createMenuRecord(request);
    }, []),
    updateMenuRecord: useCallback((request) => {
      updateMenuRecord(request);
    }, []),
    removeMenuRecord: useCallback((request) => {
      removeMenuRecord(request);
    }, []),
  };

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
};

export const Menu = () => useContext(MenuContext);
