import { postData, getData } from "./base-api";
import ApiUris from "./apiUris";

export const HomeAPI = {


    getHomeDataList(request) {
        return postData(ApiUris.Home.getHome, request);
    },
    getEventDataListById(id) {
        return getData(`${ApiUris.Home.getHomeDataById}${id}`);
    },

};
