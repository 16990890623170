import { postData, deleteData, getData } from "./base-api";
import ApiUris from "./apiUris";

export const ServiceAPI = {
    createService(request) {
        return postData(ApiUris.Service.createService, request);
    },
    updateService(request) {
        return postData(ApiUris.Service.updateService, request);
    },

    getServiceList(request) {
        return postData(ApiUris.Service.getService, request);
    },
    getServiceListById(id) {
        return getData(ApiUris.Service.getServiceById);
    },
    deleteService(id) {
        return deleteData(`${ApiUris.Service.deleteService}?id=${id}`);
    },
};
