import React, { useState, useCallback, createContext, useContext } from "react";
import { executeApi } from "./base-context";

import { NotificationContext } from "./notification-context";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { LoginContentAPI } from "../api";

export const LoginContentContext = createContext({});

export const LoginContentProvider = ({ children }) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState();
  const { addMessage } = useContext(NotificationContext);
  const [ListData, setListData] = useState([]);
  const executeEventDataAPI = async (apiFunction, request) => {
    const response = await executeApi(
      apiFunction,
      request,
      // showLoading,
      addMessage
    );
    return response;
  };

  const getList = async (request) => {
    try {
      setLoading(true);
      const res = await executeEventDataAPI(LoginContentAPI.getList, request);

      if (res.succeeded) {
        setListData(res.data);
      } else {
        addMessage("data get Failed");
        toast.error("data get Failed");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addMessage("Failed to fetch Data");
    }
  };

  const contextValue = {
    ListData,
    loading,
    getList: useCallback((request) => {
      getList(request);
    }, []),
  };

  return (
    <LoginContentContext.Provider value={contextValue}>
      {children}
    </LoginContentContext.Provider>
  );
};

export const LoginContent = () => useContext(LoginContentContext);
