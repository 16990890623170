import React, { useEffect, useState } from "react";
import { useEventData } from "../common/hooks";
import { useParams } from "react-router-dom";
import PreLoader from "../component/preLoader";
import PageBanner from "../component/PageBanner";

import { transformString } from "../common/common";
const ServiceSub = () => {
  const { getEventDataList, loading, EventListData } = useEventData();


  const [data, setData] = useState([]);

  let { id, name, menuId, subName } = useParams();
  console.log("subName", subName);
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        let payload = {
          limit: 100,
          page: 0,
          filter: { menuId: menuId },
          sorting: { fieldName: "updatedAt", direction: "Desc" },
        };
        await getEventDataList(payload);
      };
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (EventListData.content) {
      setData(EventListData[0].content);
    }
  }, [EventListData]);

  return loading && EventListData == null ? (
    <PreLoader />
  ) : EventListData?.length === 0 ? (
    <>
      <PageBanner pageName={transformString(subName ? subName : name)} />
      <div
        className="section-title text-center m-75 wow fadeInUp delay-0-2s animated"
        style={{
          visibility: "visible",
          backgroundPositionX: "rgba(255, 255, 255, 0.75)",
        }}
      >
        <h2>Page in Development</h2>
      </div>
    </>
  ) : (
    <>
      <PageBanner pageName={transformString(subName ? subName : name)} />
      <section
        className={`${"team-profile-area pt-100 rpt-80 pb-100 rpb-100 rel"} ${subName}`}
        style={{
          backgroundImage: "url(../assets/images/hero/hero-two-bg.png)",
        }}
      >
        <div className="container container-1290">
          <div className="row gap-100 pb-30 align-items-center">
            <div className="team-profile-content wow fadeInRight delay-0-2s animated"></div>
          </div>

          <div
            className="staff-content"
            dangerouslySetInnerHTML={{
              __html: EventListData[0]?.content,
            }}
          ></div>
        </div>
        {/* <div className="container">
          {data.length > 0 &&
            data.map((x) => {
              return (
                <div className="row mt-4">
                  {[...Array(x.columns)].map((y, index) => {
                    return (
                      <div
                        className={
                          x.columns === 1
                            ? "col-12"
                            : x.columns === 2
                            ? "col-md-6 col-12"
                            : x.columns === 3
                            ? "col-lg-4 col-md-6 col-12"
                            : "col"
                        }
                      >
                        {x.controls[index] === "textarea" ||
                        x.controls[index] === "textBox" ? (
                          x.values[index].value
                        ) : x.controls[index] === "ckEditor" ? (
                          <div
                            className="staff-content"
                            dangerouslySetInnerHTML={{
                              __html: x.values[index].value,
                            }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div> */}
      </section>
    </>
  );
};

export default ServiceSub;
