import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import bgImage from "../assets/images/truckTrain.jpg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import "react-phone-input-2/lib/style.css";
import { useAuth } from "../common/hooks";
import { useNavigate } from "react-router-dom";
const schema = yup
  .object({
    email: yup.string().email("Invalid email").required("Email is required"),
    lastName: yup.string().required("Email is required"),
    firstName: yup.string().required("Email is required"),
    // username: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();
const Register = () => {
  const [validNo, setValidNumber] = useState(0);
  const history = useNavigate();

  const [countryCode, setCountryCode] = useState("1");
  const [countryCodeName, setCountryCodeName] = useState("us");
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  console.log('errors', errors)

  const { Register } = useAuth()

  const value = getValues();
  const onChangeField = (phone, country) => {
    setValue("phone", phone);
    setCountryCodeName(country.name);
    setCountryCode(country.dialCode);
  };

  const onSubmit = async (values) => {
    console.log("values", values);
    if (
      values.phone === undefined ||
      (values.phone.length != validNo && !values.phone.includes("+"))
    ) {
      setError("phone", "Please enter valid number");
    } else {
      let phoneData = values.phone.toString();
      phoneData = phoneData.replace("+", "");
      values.active = true;
      values.phoneNumber = phoneData;
      values.countryCode = countryCode;
    }
    try {
      await Register(values)

    } catch (error) {
      toast.error("Invalid credentials", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {

    }
  };


  return (
    <>
      <ToastContainer />
      <div
        className="loginwrapper bg-cover bg-no-repeat bg-center flex items-center justify-center"
        style={{
          backgroundImage: `url(${bgImage})`,
          height: "100%",
        }}
      >
        <div className="lg-inner-column items-center justify-center">
          <div className="lg:w-1/2 w-full flex flex-col items-center justify-center">
            <div className="auth-box-3 register mb-50">
              <div className="text-center 2xl:mb-10 mb-5">
                <h4 className="font-medium">Register</h4>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-4 register-content"
              >
                <div className="mt-2 width-full">
                  <label className="block text-gray-700">First Name</label>
                  <input
                    type="text"
                    {...register("firstName")}
                    className={`input ${errors.firstName ? "border-red-500" : ""
                      }`}
                  />
                  {errors.firstName && (
                    <p className="error-message text-danger">
                      {errors.firstName.message}
                    </p>
                  )}
                </div>

                <div className="mt-2 width-full">
                  <label className="block text-gray-700">Last Name</label>
                  <input
                    type="text"
                    {...register("lastName")}
                    className={`input ${errors.lastName ? "border-red-500" : ""
                      }`}
                  />
                  {errors.lastName && (
                    <p className="error-message text-danger">
                      {errors.lastName.message}
                    </p>
                  )}
                </div>

                {/* <div className="mt-2 width-full">
                  <label className="block text-gray-700">Username</label>
                  <input
                    type="text"
                    {...register("username")}
                    className={`input ${
                      errors.username ? "border-red-500" : ""
                    }`}
                  />
                  {errors.username && (
                    <p className="error-message text-danger">
                      {errors.username.message}
                    </p>
                  )}
                </div> */}

                <div className="mt-2 width-full">
                  <label className="block text-gray-700">Email</label>
                  <input
                    type="email"
                    {...register("email")}
                    className={`input ${errors.email ? "border-red-500" : ""}`}
                  />
                  {errors.email && (
                    <p className="error-message text-danger">
                      {errors.email.message}
                    </p>
                  )}
                </div>

                <div className="mt-2 width-full">
                  <label className="block text-gray-700">Contact Number</label>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    isValid={(inputNumber, country, countries) => {
                      const cleanedStr = country.format.replace(/[ +]/g, "");
                      const dotCount = (cleanedStr.match(/\./g) || []).length;
                      return countries.some((country) => {
                        return (
                          startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber) ||
                          setValidNumber(dotCount)

                        );
                      });
                    }}
                    country={countryCodeName}
                    value={value.phone}
                    name={"phone"}
                    onChange={(phone, country) => {
                      onChangeField(phone, country);
                    }}
                    enableAreaCodeStretch
                    defaultErrorMessage="Invalid Phone Number"
                  />
                  {errors.phone ? (
                    <div
                      style={{
                        color: "#e85347",
                        fontSize: "12px",
                        marginTop: "0.25rem",
                      }}
                    >
                      {"please enter valid Number"}
                    </div>
                  ) : null}
                  {errors.contactNumber && (
                    <p className="error-message text-danger">
                      {errors.contactNumber.message}
                    </p>
                  )}
                </div>

                <div className="mt-2 width-full">
                  <label className="block text-gray-700">Password</label>
                  <input
                    type="password"
                    {...register("password")}
                    className={`input ${errors.password ? "border-red-500" : ""
                      }`}
                  />
                  {errors.password && (
                    <p className="error-message text-danger">
                      {errors.password.message}
                    </p>
                  )}
                </div>

                <button type="submit" className="btn-primary">
                  Register
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
