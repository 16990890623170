import React, { useState, useCallback, createContext, useContext } from "react";
import { executeApi } from "./base-context";
import { LoaderContext } from "./loader-context";
import { NotificationContext } from "./notification-context";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useLoader } from "../hooks";
import { EventDataAPI } from "../api";

export const EventDataContext = createContext({});

export const EventDataProvider = ({ children }) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState();
  const { addMessage } = useContext(NotificationContext);
  const [EventListData, setEventListData] = useState([]);
  const executeEventDataAPI = async (apiFunction, request) => {
    const response = await executeApi(
      apiFunction,
      request,
      // showLoading,
      addMessage
    );
    return response;
  };

  const getEventDataList = async (request) => {
    try {
      setLoading(true);
      const res = await executeEventDataAPI(
        EventDataAPI.getEventDataList,
        request
      );

      if (res.succeeded) {
        setEventListData(res.data);
      } else {
        addMessage("data get Failed");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addMessage("Failed to fetch Data");
    }
  };
  const getEventDataListById = async (request) => {
    console.log("request", request);
    try {
      setLoading(true);
      const res = await executeEventDataAPI(
        EventDataAPI.getEventDataListById,
        request
      );
      if (res.succeeded) {
        setEventListData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        addMessage("data get Failed");
      }
    } catch (error) {
      setLoading(false);
      addMessage("Failed to fetch Data");
    }
  };
  const createEventDataRecord = async (request) => {
    try {
      const response = await executeEventDataAPI(
        EventDataAPI.createEventData,
        request
      );

      if (response?.succeeded) {
        toast.success("EventData Add successfully");
        addMessage({ message: "EventData Add successfully", type: "success" });
        navigation("/EventData");
      } else {
        addMessage({
          message: response?.msg || "EventData Add unsuccessfully",
          type: "error",
        });
        toast.error(response?.msg || "EventData Add unsuccessfully");
      }
    } catch (error) {
      addMessage({ message: error?.msg, type: "error" });
    }
  };

  const updateEventDataRecord = async (request) => {
    try {
      const response = await executeEventDataAPI(
        EventDataAPI.updateEventData,
        request
      );

      if (response?.succeeded) {
        toast.success("EventData Update Successfully");

        addMessage({
          message: "EventData Update Successfully",
          type: "success",
        });

        navigation("/EventData");
      } else {
        addMessage({ message: response?.message, type: "error" });
      }
    } catch (error) {
      addMessage({ message: "EventData update unsuccessfully", type: "error" });
      toast.error("EventData update unsuccessfully");
    }
  };

  const removeEventDataRecord = async (id) => {
    try {
      const response = await executeEventDataAPI(
        EventDataAPI.deleteEventData,
        id
      );
      if (response?.succeeded) {
        toast.success("EventData delete successfully");
        addMessage({
          message: "EventData delete successfully",
          type: "success",
        });
        let payload = {
          limit: "100",
          offset: "0",

          sorting: {
            fieldName: "id",
            direction: "Asc",
          },
        };
        await getEventDataList(payload);
      }
    } catch (error) {
      toast.error("EventData delete unsuccessfully");
      addMessage({ message: "EventData delete unsuccessfully", type: "error" });
    }
  };

  const contextValue = {
    EventListData,
    loading,
    getEventDataList: useCallback((request) => {
      getEventDataList(request);
    }, []),
    getEventDataListById: useCallback((request) => {
      getEventDataListById(request);
    }, []),
    createEventDataRecord: useCallback((request) => {
      createEventDataRecord(request);
    }, []),
    updateEventDataRecord: useCallback((request) => {
      updateEventDataRecord(request);
    }, []),
    removeEventDataRecord: useCallback((request) => {
      removeEventDataRecord(request);
    }, []),
  };

  return (
    <EventDataContext.Provider value={contextValue}>
      {children}
    </EventDataContext.Provider>
  );
};

export const EventData = () => useContext(EventDataContext);
