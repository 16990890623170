import { componentNames } from "./component-names";

export const componentRoutes = {
  home: "/",
  dashboard: `/${componentNames.dashboard}`,
  admin: `/${componentNames.admin_role}`,
  admin_add: `/${componentNames.admin_role}/add`,
  admin_edit: `/${componentNames.admin_role}/:id`,
  admin_permission: `/${componentNames.admin_permission}`,
  invoice: `/${componentNames.invoice}`,
  admin_permission_add: `/${componentNames.admin_permission}/add`,
  admin_permission_edit: `/${componentNames.admin_permission}/:id`,
  admin_user: `/${componentNames.admin_user}`,
  admin_user_add: `/${componentNames.admin_user}/add`,
  admin_user_edit: `/${componentNames.admin_user}/:id`,
  admin_notification: `/${componentNames.admin_user}/notification`,
  admin_health: `/${componentNames.admin_health}`,
  admin_health_add: `/${componentNames.admin_health}/add`,
  admin_health_edit: `/${componentNames.admin_health}/:id`,
  admin_details_health: `/${componentNames.admin_details_health}`,
  admin_details__add: `/${componentNames.admin_details_health}/add`,
  admin_details__edit: `/${componentNames.admin_details_health}/:id`,
  article_health_details: `/${componentNames.admin_details_health}/details/:id`,
  article_health__add: `/${componentNames.admin_details_health}/sub/add/:id`,
  article_health_sub_edit: `/${componentNames.admin_details_health}/sub/:id`,
  article_resizeimg: `/${componentNames.img_resize}`,
  admin_package: `/${componentNames.admin_package}`,
  admin_package_add: `/${componentNames.admin_package}/add`,
  admin_package_edit: `/${componentNames.admin_package}/:id`,
  admin_time_slot: `/${componentNames.admin_time_slot}`,
  admin_time_slot_add: `/${componentNames.admin_time_slot}/add`,
  admin_time_slot_edit: `/${componentNames.admin_time_slot}/:id`,
  customer: `/${componentNames.customer}`,
  customer_edit: `/${componentNames.customer}/:id`,
  customer_add: `/${componentNames.customer}/add`,
  customer_details: `/${componentNames.customer}/details/:id`,
  doctor: `/${componentNames.doctor}`,
  doctorSupport: `/${componentNames.doctorSupport}`,
  doctorSupportDetail: `/${componentNames.doctorSupport}/details/:id`,
  doctor_add: `/${componentNames.doctor}/add`,
  doctor_edit: `/${componentNames.doctor}/:id`,
  doctor_details: `/${componentNames.doctor}/details/:id`,
  settings: `/${componentNames.settings}`,
  settings_add: `/${componentNames.settings}/add`,
  settings_edit: `/${componentNames.settings}/:id`,
  faqs: `/${componentNames.faq}`,
  faqs_add: `/${componentNames.faq}/add`,
  faqs_edit: `/${componentNames.faq}/:id`,
  appointment: `/${componentNames.appointment}`,
  appointment_add: `/${componentNames.appointment}/add`,
  appointment_edit: `/${componentNames.appointment}/:id`,
  appointment_details: `/${componentNames.appointment}/details/:id`,
  reSchedule_appointment: `/${componentNames.appointment}/schedule/:id`,
  log_in: "/login",
  forgot_password: "/forgetPassword",
  otp_update: "/otpUpdate",
  sign_up: "/signup",
  privacy: componentNames.privacy,
  terms: componentNames.terms,
  live_event: `/${componentNames.live_event}`,
  live_event_add: `/${componentNames.live_event}/add`,
  live_event_edit: `/${componentNames.live_event}/:id`,
  slider: `/${componentNames.slider}`,
  slider_add: `/${componentNames.slider}/add`,
  slider_edit: `/${componentNames.slider}/:id`,
  notification: componentNames.notification,
  notification_add: `/${componentNames.notification}/add`,
  notification_edit: `/${componentNames.notification}/:id`,
  notification_user: `/${componentNames.notificationUser}`,
  subscription: `/${componentNames.subscription}`,
  assessment: `/${componentNames.assessment}`,
  formAppointment: `/${componentNames.formAppointment}`,
  feedback: `/${componentNames.feedback}`,
  payment: `/${componentNames.payment}`,
  payment_details: `/${componentNames.payment}/details/:id`,
  therapy: `/${componentNames.therapy}`,
  Therapy_edit: `/${componentNames.therapy}/:id`,
  therapy_add: `/${componentNames.therapy}/add`,
  offer: `/${componentNames.offers}`,
  offer_add: `/${componentNames.offers}/add`,
  offer_edit: `/${componentNames.offers}/:id`,
  change_password: `/${componentNames.changePassword}`,
  update_profile: `/${componentNames.updateProfile}`,
  edit_profile: `/${componentNames.editProfile}/:id`,
  device: `/${componentNames.device}`,
  deviceReplace: `/${componentNames.deviceReplace}`,
  device_add: `/${componentNames.device}/add`,
  device_edit: `/${componentNames.device}/:id`,
  device_details: `/${componentNames.device}/details/:id`,
  help: `/${componentNames.help}`,
  help_details: `/${componentNames.help}/details/:id`,
  order: `/${componentNames.order}`,
  order_details: `/${componentNames.order}/details/:id`,
  deviceHistory: `/${componentNames.deviceHistory}`,
  deviceRequest: `/${componentNames.deviceRequest}`,
  medicine: `/${componentNames.medicine}`,
  medicine_edit: `/${componentNames.medicine}/:id`,
  medicine_add: `/${componentNames.medicine}/add`,
  teamManagement: `/${componentNames.teamManagement}`,
  teamManagement_add: `/${componentNames.teamManagement}/add`,
  teamManagement_edit: `/${componentNames.teamManagement}/:id`,

  inquiry: `/${componentNames.inquiry}`,
  inquiry_add: `/${componentNames.inquiry}/add`,
  inquiry_edit: `/${componentNames.inquiry}/:id`,

  accountgroup: `/${componentNames.accountgroup}`,
  accountgroup_add: `/${componentNames.accountgroup}/add`,
  accountgroup_edit: `/${componentNames.accountgroup}/:id`,

  account: `/${componentNames.account}`,
  account_add: `/${componentNames.account}/add`,
  account_edit: `/${componentNames.account}/:id`,

  transaction: `/${componentNames.transaction}`,
  transaction_add: `/${componentNames.transaction}/add`,
  transaction_edit: `/${componentNames.transaction}/:id`,

  accountname: `/${componentNames.accountname}`,
  accountname_add: `/${componentNames.accountname}/add`,
  accountname_edit: `/${componentNames.accountname}/:id`,

  finance: `/${componentNames.finance}`,
  finance_add: `/${componentNames.finance}/add`,
  finance_edit: `/${componentNames.finance}/:id`,

  patientdiscontinue: `/${componentNames.patientdiscontinue}`,
  patientdiscontinue_details: `/${componentNames.patientdiscontinue}/details/:id`,

  consultation: `/${componentNames.consultation}`,
  consultationAdd: `/${componentNames.consultationAdd}`,

  report: `/${componentNames.report}`,
  report_add: `/${componentNames.report}/add`,
  report_edit: `/${componentNames.report}/:id`,

  appointmentReport: `/${componentNames.appointmentReport}`,
  revanueReport: `${componentNames.revanueReport}`,
  subscriptionReport: `${componentNames.subscriptionReport}`,
  appointmentCityReport: `${componentNames.appointmentCityReport}`,
  followUpReport: `${componentNames.followUpReport}`,
  feedbackReport: `${componentNames.feedbackReport}`,
  treatmentReport: `${componentNames.treatmentReport}`,
  drSessionReport: `${componentNames.drSessionReport}`,
  deviceReport: `${componentNames.deviceReport}`,
  deviceReturn: `${componentNames.deviceReturn}`,
  painReport: `${componentNames.painReport}`,

  HolidayList: `${componentNames.holiday}`,
  HolidayAdd: `${componentNames.holiday}/add`,
  HolidayEdit: `${componentNames.holiday}/:id`,
  deviceHistoryReturn: `${componentNames.deviceHistory}/return/:id`,
  changeDoctor: `${componentNames.changeDoctor}/:id`,
  newCase: `${componentNames.newcase}`,
  sendNotification: `${componentNames.notificationUser}`,
  // customer_details: `/${componentNames.customer}/details/:id`,
};
