import React, { useState, useCallback, createContext, useContext } from "react";
import { executeApi } from "./base-context";
import { LoaderContext } from "./loader-context";
import { NotificationContext } from "./notification-context";
import { TestimonialsAPI } from "../api";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useLoader } from "../hooks";


export const TestimonialsContext = createContext({});

export const TestimonialsProvider = ({ children }) => {
    const navigation = useNavigate();
    const [loading, setLoading] = useState();
    const { addMessage } = useContext(NotificationContext);
    const [testimonialsList, setTestimonialsList] = useState([]);
    const executeTestimonialsAPI = async (apiFunction, request) => {
        const response = await executeApi(
            apiFunction,
            request,
            // showLoading,
            addMessage
        );
        return response;
    };

    const getTestimonialsList = async (request) => {
        try {
            setLoading(true);
            const res = await executeTestimonialsAPI(TestimonialsAPI.getTestimonialsList, request);
            if (res.succeeded) {
                setTestimonialsList(res.data);
            } else {
                setLoading(false);
                addMessage("data get Failed");
            }
        } catch (error) {
            setLoading(false);
            addMessage("Failed to fetch Data");
        }
    };
    const getTestimonialsListById = async (request) => {
        try {
            setLoading(true);
            const res = await executeTestimonialsAPI(TestimonialsAPI.getTestimonialsListById, request);
            if (res.succeeded) {
                setTestimonialsList(res.data);
                setLoading(false);
            } else {
                setLoading(false);
                addMessage("data get Failed");
            }
        } catch (error) {
            setLoading(false);
            addMessage("Failed to fetch Data");
        }
    };
    const createTestimonialsRecord = async (request) => {
        try {
            const response = await executeTestimonialsAPI(TestimonialsAPI.createTestimonials, request);

            if (response?.succeeded) {
                toast.success("Testimonials Add successfully");
                addMessage({ message: "Testimonials Add successfully", type: "success" });
                navigation("/Testimonials");
            } else {
                addMessage({
                    message: response?.msg || "Testimonials Add unsuccessfully",
                    type: "error",
                });
                toast.error(response?.msg || "Testimonials Add unsuccessfully");
            }
        } catch (error) {
            addMessage({ message: error?.msg, type: "error" });
        }
    };

    const updateTestimonialsRecord = async (request) => {
        try {
            const response = await executeTestimonialsAPI(TestimonialsAPI.updateTestimonials, request);

            if (response?.succeeded) {
                toast.success("Testimonials Update Successfully");

                addMessage({ message: "Testimonials Update Successfully", type: "success" });

                navigation("/Testimonials");
            } else {
                addMessage({ message: response?.message, type: "error" });
            }
        } catch (error) {
            addMessage({ message: "Testimonials update unsuccessfully", type: "error" });
            toast.error("Testimonials update unsuccessfully");
        }
    };

    const removeTestimonialsRecord = async (id) => {
        try {
            const response = await executeTestimonialsAPI(TestimonialsAPI.deleteTestimonials, id);
            if (response?.succeeded) {
                toast.success("Testimonials delete successfully");
                addMessage({ message: "Testimonials delete successfully", type: "success" });
                let payload = {
                    limit: "100",
                    offset: "0",

                    sorting: {
                        fieldName: "id",
                        direction: "Asc",
                    },
                };
                await getTestimonialsList(payload);
            }
        } catch (error) {
            toast.error("Testimonials delete unsuccessfully");
            addMessage({ message: "Testimonials delete unsuccessfully", type: "error" });
        }
    };

    const contextValue = {
        testimonialsList,
        loading,
        getTestimonialsList: useCallback((request) => {
            getTestimonialsList(request);
        }, []),
        getTestimonialsListById: useCallback((request) => {
            getTestimonialsListById(request);
        }, []),
        createTestimonialsRecord: useCallback((request) => {
            createTestimonialsRecord(request);
        }, []),
        updateTestimonialsRecord: useCallback((request) => {
            updateTestimonialsRecord(request);
        }, []),
        removeTestimonialsRecord: useCallback((request) => {
            removeTestimonialsRecord(request);
        }, []),
    };

    return (
        <TestimonialsContext.Provider value={contextValue}>
            {children}
        </TestimonialsContext.Provider>
    );
};

export const Testimonials = () => useContext(TestimonialsContext);
