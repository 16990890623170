const apiUris = {};

export const baseUri = "https://cttnow.com/adminApi/api";
export const baseUriImage = "https://cttnow.com/adminApi/CTTImage/";

const AUTHENTICATE_ROOT = `${baseUri}`;
const authenticate = {
  loginUser: `${AUTHENTICATE_ROOT}/Users/CustomerLogin`,
  getUser: `${AUTHENTICATE_ROOT}/get`,
  logout: `${AUTHENTICATE_ROOT}/logout`,
  forgotPassword: `${AUTHENTICATE_ROOT}/forget-password`,
  resetPassword: `${AUTHENTICATE_ROOT}/reset-password`,
  changePassword: `${AUTHENTICATE_ROOT}/changepassword`,
  Register: `${AUTHENTICATE_ROOT}/Customer/Create`,
};

apiUris.authUser = authenticate;

const User = {
  getUser: `${AUTHENTICATE_ROOT}/Users/List`,
  createUser: `${AUTHENTICATE_ROOT}/Users/Create`,
  deleteUser: `${AUTHENTICATE_ROOT}/Users`,
  updateUser: `${AUTHENTICATE_ROOT}/Users/Update`,
};

apiUris.user = User;
const Staff = {
  getStaff: `${AUTHENTICATE_ROOT}/Staff/List`,
  getStaffById: `${AUTHENTICATE_ROOT}/Staff/List`,
  createStaff: `${AUTHENTICATE_ROOT}/Staff/Create`,
  deleteStaff: `${AUTHENTICATE_ROOT}/Staff`,
  updateStaff: `${AUTHENTICATE_ROOT}/Staff/Update`,
};

apiUris.staff = Staff;

const Service = {
  getService: `${AUTHENTICATE_ROOT}/ServiceContents/List`,
  getServiceById: `${AUTHENTICATE_ROOT}/ServiceContents/`,
  createService: `${AUTHENTICATE_ROOT}/ServiceContents/Create`,
  deleteService: `${AUTHENTICATE_ROOT}/ServiceContents`,
  updateService: `${AUTHENTICATE_ROOT}/ServiceContents/Update`,
};

apiUris.Service = Service;

const FreeContent = {
  getFreeContent: `${AUTHENTICATE_ROOT}/FreeContent/List`,
  createFreeContent: `${AUTHENTICATE_ROOT}/FreeContent/Create`,
  deleteFreeContent: `${AUTHENTICATE_ROOT}/FreeContent`,
  updateFreeContent: `${AUTHENTICATE_ROOT}/FreeContent/Update`,
};

apiUris.FreeContent = FreeContent;

const Menu = {
  getMenu: `${AUTHENTICATE_ROOT}/Menu/List`,
  createMenu: `${AUTHENTICATE_ROOT}/Menu/Create`,
  deleteMenu: `${AUTHENTICATE_ROOT}/Menu`,
  updateMenu: `${AUTHENTICATE_ROOT}/Menu/Update`,
};

apiUris.menu = Menu;

const Permission = {
  getPermission: `${AUTHENTICATE_ROOT}/Permissions/List`,
  createPermission: `${AUTHENTICATE_ROOT}/Permissions/Create`,
  deletePermission: `${AUTHENTICATE_ROOT}/Permissions`,
  updatePermission: `${AUTHENTICATE_ROOT}/Permissions/Update`,
};

apiUris.permission = Permission;

const LoginContent = {
  get: `${AUTHENTICATE_ROOT}/LoginContent/List`,
};

apiUris.LoginContent = LoginContent;

const EventData = {
  getEventData: `${AUTHENTICATE_ROOT}/Event/List`,
  getEventDataById: `${AUTHENTICATE_ROOT}/Event/`,
  createEventData: `${AUTHENTICATE_ROOT}/Event/Create`,
  deleteEventData: `${AUTHENTICATE_ROOT}/Event`,
  updateEventData: `${AUTHENTICATE_ROOT}/Event/Update`,
  uploadImage: `${AUTHENTICATE_ROOT}`,
};

apiUris.EventData = EventData;

const Testimonials = {
  getTestimonials: `${AUTHENTICATE_ROOT}/Testimonial/List`,
  getTestimonialsById: `${AUTHENTICATE_ROOT}/Testimonial/`,
  createTestimonials: `${AUTHENTICATE_ROOT}/Testimonial/Create`,
  deleteTestimonials: `${AUTHENTICATE_ROOT}/Testimonial`,
  updateTestimonials: `${AUTHENTICATE_ROOT}/Testimonial/Update`,
};
apiUris.Testimonials = Testimonials;

const Home = {
  getHome: `${AUTHENTICATE_ROOT}/DashboardDetails/List`,
  getHomeDataById: `${AUTHENTICATE_ROOT}/DashboardDetails/`,
  createHomeData: `${AUTHENTICATE_ROOT}/DashboardDetails/Create`,
  deleteHomeData: `${AUTHENTICATE_ROOT}/DashboardDetails`,
  updateHomeData: `${AUTHENTICATE_ROOT}/DashboardDetails/Update`,
};

apiUris.Home = Home;

export default apiUris;
