import { Link } from "react-router-dom";

const E404 = () => {
  return (
    <section
      className="error-section pt-220 rpt-120 pb-100 rpb-80 rel z-1"
      style={{ backgroundImage: "url(assets/images/hero/hero-two-bg.png)" }}
    >
      <div className="container container-1290">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="error-content rmb-55 wow fadeInRight delay-0-2s animated">
              <h1>OPPS!</h1>
              <div className="section-title mb-50 rmb-35">
                <h2>This Page Are Can't Be Found</h2>
              </div>
              <Link to="/">
                <a className="theme-btn style-two">
                  Go to Home <i className="far fa-arrow-right" />
                </a>
              </Link>
              <div className="social-style-four d-flex mt-60 rmt-35">
                <div className="social-style-four d-flex mt-60 rmt-35">
                  <a href="https://www.facebook.com/CommercialTruckTraining/">
                    <i className="fab fa-facebook-f" /> <span>Facebook</span>
                  </a>
                  <a href="https://www.youtube.com/channel/UCLGeCxEYgIh-yQcW1l1Kocw">
                    <i className="fab fa-youtube" /> <span>YouTube</span>
                  </a>
                  <a href="https://www.aedin.com/company/commercial-truck-training">
                    <i className="fab fa-aedin" /> <span>aedin</span>
                  </a>
                  <a href="https://www.instagram.com/commercialtrucktraining/">
                    <i className="fab fa-instagram" /> <span>Instagram</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="error-image wow zoomIn delay-0-2s">
              <img
                src="assets/images/background/404-error.png"
                alt="404 Error"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default E404;
