import { postData, deleteData, getData } from "./base-api";
import ApiUris from "./apiUris";

export const TestimonialsAPI = {
    createTestimonials(request) {
        return postData(ApiUris.Testimonials.createTestimonials, request);
    },
    updateTestimonials(request) {
        return postData(ApiUris.Testimonials.updateTestimonials, request);
    },

    getTestimonialsList(request) {
        return postData(ApiUris.Testimonials.getTestimonials, request);
    },
    getTestimonialsListById(id) {
        return getData(ApiUris.Testimonials.getTestimonialsById);
    },
    deleteTestimonials(id) {
        return deleteData(`${ApiUris.Testimonials.deleteTestimonials}?id=${id}`);
    },
};
