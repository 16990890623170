import React from "react";

import {
  AuthProvider,
  StaffProvider,
  LoaderProvider,
  NotificationProvider,
  FreeContentProvider,
  ServiceProvider,
  EventDataProvider,
  TestimonialsProvider,
  LoginContentProvider,
  HomeDataProvider
} from "../common/contexts";
import Compose from "./compose";
import { MenuProvider } from "../common/contexts/menu-context";

export const AppContextWrapper = ({ children }) => {
  const providers = [
    AuthProvider,
    StaffProvider,
    MenuProvider,
    LoaderProvider,
    NotificationProvider,
    FreeContentProvider,
    ServiceProvider,
    EventDataProvider,
    TestimonialsProvider,
    LoginContentProvider,
    HomeDataProvider
  ];
  return <Compose components={providers}>{children}</Compose>;
};
