/**
 * account create status validation messages
 */
export const ACCOUNT_CREATE_STATUS = [
  "Failed",
  "Success; verification send.",
  "Success; verification failed.",
  "Account email already exists.",
  "Account phone already exists.",
  "Your passwords do not match.",
  "Not a valid email address.",
];

/**
 * account login status validation messages
 */
export const ACCOUNT_LOGIN_STATUS = [
  "Failed",
  "Success",
  "Your email does not exist, please sign up.",
  "Your account already exists.",
  "Your account has been disabled, please contact support@zillmeter.com",
  "Your account was locked due to several failed login attempts.",
  "Not verified.",
  "You must change your password.",
  "Password does not match.",
  "Not a valid email address.",
];

/**
 * account recovery change submit status
 */
export const ACCOUNT_RECOVERY_CHANGE_SUBMIT_STATUS = [
  "Failed",
  "Success",
  "This token has already been used",
  "Your account is expired, please contact support@zillmeter.com",
  "Your account does not exist",
  "Your account password should not be old password, please create new one.",
];

/**
 * account recovery init status messages
 */
export const ACCOUNT_RECOVERY_INIT_STATUS = [
  "Failed",
  "Success",
  "Account does not exist",
  "You have exceeded the limit, please contact support@zillmeter.com",
  "Your account is not verified.",
];

/**
 * account verify status
 */
export const ACCOUNT_VERIFY_STATUS = [
  "Failed",
  "Success",
  "Already verified",
  "Invalid code",
];

/**
 * account update status messages
 */
export const ACCOUNT_UPDATE_STATUS = [
  "Failed to save Account information.",
  "Account information updated successfully.",
  "Account does not exist.",
];

/**
 * Admin permissions
 */

export const ADMIN_PERMISSIONS = [
  "Dashboard",
  "Finance",
  "Device",
  "Report",
  "Inquiry",
  "Admin Management",
  "Finance Management",
  "Device Management",
  "Device Delivery",
  "Doctor",
  "Appointment",
  "New case",
  "Faqs",
  "Settings Management",
  "Live Event",
  "Package Management",
  "Health Article",
  "Form Appointment",
  "Assessment",
  "Feedback",
  "Payment",
  "Therapy",
  "Offers",
  "customer",
  "Support",
  "Holiday",
  "order",
  "teamManagement",
  "inquiry",
  "medicine",
  "invoice",
  "patientDiscontinue",
];
