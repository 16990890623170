// Login.js
import React, { useState } from "react";
import PreLoader from "../component/preLoader";
import PageBanner from "../component/PageBanner";



const Checkout = () => {
    const [loading, setLoading] = useState(true)
    setTimeout(() => {
        console.log("loading")
        setLoading(false)
    }, 200);

    return loading ? <PreLoader /> : (
        <>

            {/* Page Banner Start */}
            <PageBanner pageName={"Checkout"} />
            {/* Page Banner End */}

            <div className="container mb-50 text-area">
                <div className="section-title text-center m-20 wow fadeInUp delay-0-2s">
                    <div className="p-50 bgc-lighter mt-55 wow fadeInUp delay-0-2s">
                        <h4 className="text-start">PAY BY CREDIT CARD</h4>
                        <div className="row mt-30" style={{ height: "160px" }}>
                            <div class="powr-paypal-button" id="78de3bf5_1473954350"></div><script src="https://www.powr.io/powr.js?platform=wordpress"></script>
                        </div>
                    </div>
                </div>

                <div className="section-title text-center m-20 wow fadeInUp delay-0-2s">
                    <div className="text-start mt-20 mb-0 wow fadeInUp delay-0-2s">
                        <h6 className="mt-70">Pay by Check (via Emailed Invoice):</h6>
                        <p>
                            Please note: payment will now be processed by Nexus Business
                            Solutions
                        </p>
                    </div>

                    <div className="powr-contact-form" id="ae155321_1461341902424"></div>
                </div>
            </div>
        </>
    );
};

export default Checkout;
