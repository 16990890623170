import React, { useState, useCallback, createContext, useContext } from "react";
import { executeApi } from "./base-context";
import { LoaderContext } from "./loader-context";
import { NotificationContext } from "./notification-context";
import { StaffAPI } from "../api";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useLoader } from "../hooks";
export const StaffContext = createContext({});

export const StaffProvider = ({ children }) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState();
  const [is404, setIs404] = useState();
  const { addMessage } = useContext(NotificationContext);
  const [listData, setListData] = useState([]);
  const executeStaffAPI = async (apiFunction, request) => {
    const response = await executeApi(
      apiFunction,
      request,
      // showLoading,
      addMessage
    );
    return response;
  };

  const getStaffList = async (request) => {
    try {
      setIs404(false);
      setLoading(true);
      const res = await executeStaffAPI(StaffAPI.getStaffList, request);

      if (res.succeeded) {

        // if (res.data.length == 0) {
        //   setIs404(true);
        //   setListData(res.data);
        // } else {
        setLoading(false);
        setListData(res.data);
        setIs404(false);
        // }
      } else {
        setLoading(false);
        addMessage("data get Failed");
      }
    } catch (error) {
      setLoading(false);
      addMessage("Failed to fetch Data");
    }
  };
  const getStaffListById = async (request) => {
    try {
      setLoading(true);
      const res = await executeStaffAPI(StaffAPI.getStaffList, request);
      if (res.succeeded) {
        setListData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        addMessage("data get Failed");
      }
    } catch (error) {
      setLoading(false);
      addMessage("Failed to fetch Data");
    }
  };
  const createStaffRecord = async (request) => {
    try {
      const response = await executeStaffAPI(StaffAPI.createStaff, request);

      if (response?.succeeded) {
        toast.success("Staff Add successfully");
        addMessage({ message: "Staff Add successfully", type: "success" });
        navigation("/Staff");
      } else {
        addMessage({
          message: response?.msg || "Staff Add unsuccessfully",
          type: "error",
        });
        toast.error(response?.msg || "Staff Add unsuccessfully");
      }
    } catch (error) {
      addMessage({ message: error?.msg, type: "error" });
    }
  };

  const updateStaffRecord = async (request) => {
    try {
      const response = await executeStaffAPI(StaffAPI.updateStaff, request);

      if (response?.succeeded) {
        toast.success("Staff Update Successfully");

        addMessage({ message: "Staff Update Successfully", type: "success" });

        navigation("/Staff");
      } else {
        addMessage({ message: response?.message, type: "error" });
      }
    } catch (error) {
      addMessage({ message: "Staff update unsuccessfully", type: "error" });
      toast.error("Staff update unsuccessfully");
    }
  };

  const removeStaffRecord = async (id) => {
    try {
      const response = await executeStaffAPI(StaffAPI.deleteStaff, id);
      if (response?.succeeded) {
        toast.success("Staff delete successfully");
        addMessage({ message: "Staff delete successfully", type: "success" });
        let payload = {
          limit: "100",
          offset: "0",

          sorting: {
            fieldName: "id",
            direction: "Asc",
          },
        };
        await getStaffList(payload);
      }
    } catch (error) {
      toast.error("Staff delete unsuccessfully");
      addMessage({ message: "Staff delete unsuccessfully", type: "error" });
    }
  };

  const contextValue = {
    listData,
    loading,
    is404,
    getStaffList: useCallback((request) => {
      getStaffList(request);
    }, []),
    getStaffListById: useCallback((request) => {
      getStaffListById(request);
    }, []),
    createStaffRecord: useCallback((request) => {
      createStaffRecord(request);
    }, []),
    updateStaffRecord: useCallback((request) => {
      updateStaffRecord(request);
    }, []),
    removeStaffRecord: useCallback((request) => {
      removeStaffRecord(request);
    }, []),
  };

  return (
    <StaffContext.Provider value={contextValue}>
      {children}
    </StaffContext.Provider>
  );
};

export const Staff = () => useContext(StaffContext);
