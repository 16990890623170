import React, { Fragment, useEffect, useState } from "react";
import { useMenu } from "../common/hooks";
import { uniq, orderBy } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../common/hooks";

const Menu = ({ singleMenu, onCloseModal }) => {
  const { menuListData, getMenuListData } = useMenu();
  const { loggedIn } = useAuth();
  const [menuOption, setMenuOption] = useState([]);

  useEffect(() => {
    let payload = {
      limit: 100,
      page: 0,
      sorting: { fieldName: "parent", direction: "Asc" },
    };
    getMenuListData(payload);
  }, []);

  useEffect(() => {
    let finalData = [];

    let menuListParent = menuListData.filter(
      (x) => x.parent === 0 && x.deleted === false
    );
    let orderData = orderBy(menuListParent, ["orderNumber"], ["asc"]);

    orderData.forEach((parent) => {
      let parentWithChildren = {
        parent: parent,
        children: [],
      };

      let children = menuListData
        .filter(
          (child) => child.parent === parent.id && child.deleted === false
        )
        .sort((a, b) => a.orderNumber - b.orderNumber);

      children.forEach((child) => {
        let childWithGrandchildren = {
          child: child,
          grandchildren: [],
        };

        let grandchildren = menuListData
          .filter(
            (grandchild) =>
              grandchild.parent === child.id && grandchild.deleted === false
          )
          .sort((a, b) => a.orderNumber - b.orderNumber);

        childWithGrandchildren.grandchildren = grandchildren;

        parentWithChildren.children.push(childWithGrandchildren);
      });

      finalData.push(parentWithChildren);
    });
    setMenuOption(finalData);
  }, [menuListData]);

  return (
    menuOption &&
    menuOption.length !== 0 && (
      <Fragment>
        {singleMenu ? (
          <SingleMenu />
        ) : (
          <Fragment>
            <DaskTopMenu final={menuOption} loggedIn={loggedIn} />
            <MobileMenu
              final={menuOption}
              loggedIn={loggedIn}
              onCloseModal={onCloseModal}
            />
          </Fragment>
        )}
      </Fragment>
    )
  );
};

export default Menu;

const DaskTopMenu = ({ final, loggedIn }) => {
  const location = useLocation();

  function toCamelCase(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  return (
    <ul className="navigation d-none d-xl-flex desktop-menu">
      {final.map((group, index) => {
        return (
          <li className="dropdown" key={index}>
            {" "}
            {group.parent.name === "Home" ? (
              <Link to="/">{group.parent.name}</Link>
            ) : (
              <Link to="#">{group.parent.name}</Link>
            )}
            {group.children.length > 0 && (
              <>
                <div class="dropdown-btn">
                  <span class="far fa-plus"></span>
                </div>
                <ul key={index}>
                  {group.children.map((subGroup, i) => {
                    let link, target;
                    if (group.parent.id === 29) {
                      link =
                        subGroup.child.link == null ||
                          subGroup.child.link == "" ||
                          subGroup.child.name === "Testimonial"
                          ? `/${toCamelCase(group.parent.slug)}/${toCamelCase(
                            subGroup.child.slug
                          )}/${subGroup.child.id}`
                          : "/404";

                      target =
                        subGroup.child.link == null ||
                          subGroup.child.link === ""
                          ? "_self"
                          : "_blank";
                      if (subGroup.child.isDesktop === true && !loggedIn) {
                        return;
                      } else {
                        if (subGroup.child.isDesktop && loggedIn) {
                          return (
                            <li className={"dropdown"} key={i}>
                              <Link
                                to={link}
                                className={
                                  link.includes(location.pathname)
                                    ? "active"
                                    : ""
                                }
                                target={target}
                              >
                                {subGroup.child.name}
                              </Link>
                              {subGroup.grandchildren.length > 0 && (
                                <>
                                  <div class="dropdown-btn">
                                    <span class="far fa-plus"></span>
                                  </div>
                                  <ul>
                                    {subGroup.grandchildren.map(
                                      (subGroupChild, index) => {
                                        return (
                                          <li
                                            className={
                                              subGroupChild.length > 0
                                                ? "dropdown"
                                                : ""
                                            }
                                            key={index}
                                          >
                                            <Link
                                              to={
                                                subGroupChild.link == null
                                                  ? group.parent.id === 17
                                                    ? `/eventDetails/${subGroupChild.name}/${subGroupChild.id}`
                                                    : `${link}/${toCamelCase(
                                                      subGroupChild.name
                                                    )}/${subGroupChild.id}`
                                                  : subGroupChild.link
                                              }
                                              target={
                                                subGroupChild.link == null
                                                  ? "_self"
                                                  : "_blank"
                                              }
                                            >
                                              {subGroupChild.name}
                                            </Link>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </>
                              )}
                            </li>
                          );
                        } else if (
                          subGroup.child.isDesktop === false &&
                          !loggedIn
                        ) {
                          return (
                            <li className={"dropdown"} key={i}>
                              <Link
                                to={link}
                                className={
                                  link.includes(location.pathname)
                                    ? "active"
                                    : ""
                                }
                                target={target}
                              >
                                {subGroup.child.name}
                              </Link>
                              {subGroup.grandchildren.length > 0 && (
                                <>
                                  <div class="dropdown-btn">
                                    <span class="far fa-plus"></span>
                                  </div>
                                  <ul>
                                    {subGroup.grandchildren.map(
                                      (subGroupChild, index) => {
                                        console.log('subGroupChild', subGroupChild)
                                        return (
                                          <li
                                            className={
                                              subGroupChild.length > 0
                                                ? "dropdown"
                                                : ""
                                            }
                                            key={index}
                                          >
                                            <Link
                                              to={
                                                subGroupChild.link == null
                                                  ? group.parent.id === 17
                                                    ? `/eventDetails/${subGroupChild.name}/${subGroupChild.id}`
                                                    : `${link}/${toCamelCase(
                                                      subGroupChild.name
                                                    )}/${subGroupChild.id}`
                                                  : subGroupChild.link
                                              }
                                              target={
                                                subGroupChild.link == null
                                                  ? "_self"
                                                  : "_blank"
                                              }
                                            >
                                              {subGroupChild.name}
                                            </Link>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </>
                              )}
                            </li>
                          );
                        }
                      }
                    } else {
                      link =
                        subGroup.child.link == null ||
                          subGroup.child.link == "" ||
                          subGroup.child.name === "Testimonial"
                          ? `/${toCamelCase(group.parent.slug)}/${toCamelCase(
                            subGroup.child.slug
                          )}/${subGroup.child.id}`
                          : subGroup.child.link;



                      target =
                        subGroup.child.link == null ||
                          subGroup.child.link === ""
                          ? "_self"
                          : "_blank";

                      return (
                        <li className={"dropdown"} key={i}>
                          <Link
                            to={link}
                            className={
                              link.includes(location.pathname) ? "active" : ""
                            }
                            target={target}
                          >
                            {subGroup.child.name}
                          </Link>

                          {subGroup.grandchildren.length > 0 && (
                            <>
                              <div class="dropdown-btn">
                                <span class="far fa-plus"></span>
                              </div>
                              <ul>
                                {subGroup.grandchildren.map(
                                  (subGroupChild, index) => {
                                    return (
                                      <li
                                        className={
                                          subGroupChild.length > 0
                                            ? "dropdown"
                                            : ""
                                        }
                                        key={index}
                                      >
                                        <Link
                                          to={
                                            subGroupChild.link == null
                                              ? group.parent.id === 17
                                                ? `/eventDetails/${subGroupChild.slug}/${subGroupChild.id}`
                                                : `${link}/${toCamelCase(
                                                  subGroupChild.slug
                                                )}/${subGroupChild.id}`
                                              : `/eventDetails/${subGroupChild.slug}/${subGroupChild.id}`
                                          }
                                          target={
                                            subGroupChild.link == null || subGroupChild.link == ""
                                              ? "_self"
                                              : "_blank"
                                          }
                                        >

                                          {subGroupChild.name}
                                        </Link>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </>
                          )}
                        </li>
                      );
                    }

                    // }
                  })}
                </ul>
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const MobileMenu = ({ final, loggedIn, onCloseModal }) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState("");

  const [multiMenu, setMultiMenu] = useState("");

  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };

  const activeMenuSet = (value) =>
    setActiveMenu(activeMenu === value ? "" : value);
  const activeLi = (value) =>
    value === activeMenu ? { display: "block" } : { display: "none" };

  const multiMenuSet = (value) =>
    setMultiMenu(multiMenu === value ? "" : value);
  const multiMenuActiveLi = (value) =>
    value === multiMenu ? { display: "block" } : { display: "none" };

  return (
    <ul className="navigation d-block d-xl-none mobile-menu">
      {final.map((group, index) => (
        <li className="dropdown" key={index}>
          <a
            href="#"
            onClick={() => {
              activeMenuSet(group.parent.name);
              onCloseModal();
            }}
          >
            {group.parent.name}
            {/* {group.children.length > 0 && (
              <span className="far fa-plus" style={{ color: "white" }}></span>
            )} */}
          </a>
          <ul style={activeLi(group.parent.name)}>
            {group.children.map((subGroup, i) => {
              let link, target;
              if (group.parent.id === 29) {
                link =
                  subGroup.child.link == null ||
                    subGroup.child.link == "" ||
                    subGroup.child.name === "Testimonial"
                    ? `/${toCamelCase(group.parent.name)}/${toCamelCase(
                      subGroup.child.name
                    )}/${subGroup.child.id}`
                    : "/404";

                target =
                  subGroup.child.link == null || subGroup.child.link === ""
                    ? "_self"
                    : "_blank";
                if (subGroup.child.isDesktop === true && !loggedIn) {
                  return;
                } else {
                  if (subGroup.child.isDesktop && loggedIn) {
                    return (
                      <li className={"dropdown"} key={i}>
                        <Link
                          to={link}
                          className={
                            link.includes(location.pathname) ? "active" : ""
                          }
                          target={target}
                        >
                          {subGroup.child.name}
                        </Link>
                        {subGroup.grandchildren.length > 0 && (
                          <>
                            <div class="dropdown-btn">
                              <span class="far fa-plus"></span>
                            </div>
                            <ul>
                              {subGroup.grandchildren.map(
                                (subGroupChild, index) => {
                                  return (
                                    <li
                                      className={
                                        subGroupChild.length > 0
                                          ? "dropdown"
                                          : ""
                                      }
                                      key={index}
                                    >
                                      <Link
                                        to={
                                          subGroupChild.link == null
                                            ? group.parent.id === 17
                                              ? `/eventDetails/${subGroupChild.name}/${subGroupChild.id}`
                                              : `${link}/${toCamelCase(
                                                subGroupChild.name
                                              )}/${subGroupChild.id}`
                                            : subGroupChild.link
                                        }
                                        target={
                                          subGroupChild.link == null
                                            ? "_self"
                                            : "_blank"
                                        }
                                      >
                                        {subGroupChild.name}
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        )}
                      </li>
                    );
                  } else if (subGroup.child.isDesktop === false && !loggedIn) {
                    return (
                      <li className={"dropdown"} key={i}>
                        <Link
                          to={link}
                          className={
                            link.includes(location.pathname) ? "active" : ""
                          }
                          target={target}
                        >
                          {subGroup.child.name}
                        </Link>
                        {subGroup.grandchildren.length > 0 && (
                          <>
                            <div class="dropdown-btn">
                              <span class="far fa-plus"></span>
                            </div>
                            <ul>
                              {subGroup.grandchildren.map(
                                (subGroupChild, index) => {
                                  return (
                                    <li
                                      className={
                                        subGroupChild.length > 0
                                          ? "dropdown"
                                          : ""
                                      }
                                      key={index}
                                    >
                                      <Link
                                        to={
                                          subGroupChild.link == null
                                            ? group.parent.id === 17
                                              ? `/eventDetails/${subGroupChild.name}/${subGroupChild.id}`
                                              : `${link}/${toCamelCase(
                                                subGroupChild.name
                                              )}/${subGroupChild.id}`
                                            : subGroupChild.link
                                        }
                                        target={
                                          subGroupChild.link == null
                                            ? "_self"
                                            : "_blank"
                                        }
                                      >
                                        {subGroupChild.name}
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        )}
                      </li>
                    );
                  }
                }
              } else {
                link =
                  subGroup.child.link == null ||
                    subGroup.child.link == "" ||
                    subGroup.child.name === "Testimonial"
                    ? `/${toCamelCase(group.parent.name)}/${toCamelCase(
                      subGroup.child.name
                    )}/${subGroup.child.id}`
                    : "/404";

                target =
                  subGroup.child.link == null || subGroup.child.link === ""
                    ? "_self"
                    : "_blank";
                return (
                  <li className={"dropdown"} key={i}>
                    <Link
                      to={link}
                      className={
                        link.includes(location.pathname) ? "active" : ""
                      }
                      target={target}
                    >
                      {subGroup.child.name}
                    </Link>
                    {subGroup.grandchildren.length > 0 && (
                      <>
                        <div class="dropdown-btn">
                          <span class="far fa-plus"></span>
                        </div>
                        <ul>
                          {subGroup.grandchildren.map(
                            (subGroupChild, index) => {
                              return (
                                <li
                                  className={
                                    subGroupChild.length > 0 ? "dropdown" : ""
                                  }
                                  key={index}
                                >
                                  <Link
                                    to={
                                      subGroupChild.link == null
                                        ? group.parent.id === 17
                                          ? `/eventDetails/${subGroupChild.name}/${subGroupChild.id}`
                                          : `${link}/${toCamelCase(
                                            subGroupChild.name
                                          )}/${subGroupChild.id}`
                                        : subGroupChild.link
                                    }
                                    target={
                                      subGroupChild.link == null
                                        ? "_self"
                                        : "_blank"
                                    }
                                  >
                                    {subGroupChild.name}
                                  </Link>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </>
                    )}
                  </li>
                );
              }
            })}
          </ul>
          {group.children.length > 0 && (
            <div
              className="dropdown-btn"
              onClick={() => activeMenuSet(group.parent.name)}
            >
              <div class="dropdown-btn">
                <span class="far fa-plus"></span>
              </div>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

const SingleMenu = () => {
  return (
    <ul className="navigation onepage clearfix">
      <li>
        <a href="#home">Home</a>
      </li>
      <li>
        <a href="#about">About</a>
      </li>
      <li>
        <a href="#services">Services</a>
      </li>
      <li>
        <a href="#projects">Projects</a>
      </li>
      <li>
        <a href="#pricing">Pricing</a>
      </li>
      <li>
        <a href="#news">News</a>
      </li>
    </ul>
  );
};
