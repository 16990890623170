import { postData, putData, getData, deleteData } from "./base-api";
import ApiUris from "./apiUris";

export const MenuAPI = {
  createMenu(request) {
    return postData(ApiUris.menu.createMenu, request);
  },
  updateMenu(request) {
    return postData(ApiUris.menu.updateMenu, request);
  },

  getMenuList(request) {
    return postData(ApiUris.menu.getMenu, request);
  },
  getMenuListById(id) {
    return postData(ApiUris.menu.getMenu + `?id=${id}`);
  },
  deleteMenu(id) {
    return deleteData(`${ApiUris.menu.deleteMenu}?id=${id}`);
  },
};
