// Login.js
import React, { useEffect } from "react";
import {useEventData} from '../common/hooks'
import PageBanner from "../component/PageBanner";
import { Link } from "react-router-dom";
import PreLoader from "../component/preLoader";



const Confirmation = () => {
    const {getEventDataList,EventListData,loading}=useEventData()
    useEffect(() => {
        
          const fetchData = async () => {
            let payload = {
              limit: 100,
              page: 0,
    
              sorting: { fieldName: "UpdatedAt", direction: "Desc" },
            };
            await getEventDataList(payload);
          };
          fetchData();
        
      }, []);
      const colors = [
        "rgb(237, 28, 36)",
        "rgb(2, 46, 110)",
        "rgb(0, 114, 188)",
        "rgb(225, 30, 54)",
        "rgb(54, 46, 86)",
        "rgb(245, 134, 52)",
      ];
    return (
        <>
            {/* <PreLoader /> */}
            {/* Page Banner Start */}
            <PageBanner pageName={"Confirmation"} parentName={"Ultimate Boot Camp"} childParent={"Virginia Beach"} />
            {/* Page Banner End */}

            <div className="container">
                {/* Service Style Two start */}
                <section className="service-two-area pt-90 rpt-45 pb-100 rpb-70">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="row">
                                    <div className="col-xl-10">
                                        <div className="section-title mb-30 wow fadeInRight delay-0-2s">
                                            <div className="text pt-20">
                                                <p>
                                                    Thank you for registering for the <a href="a" className="link2">Ultimate Boot Camp!</a> You’re all set for now. If there are any logistical issues regarding payment, our Will Brogan will call to make sure everything is good to go. Please save this page for this information:
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                        { EventListData?.length > 0 && (
          <div className="container">
            <div className="row justify-content-center">
              <div className="row">
                <div className="col-xl-10">
                  <div
                    className="section-title mb-30 wow fadeInRight delay-0-2s animated"
                    style={{ visibility: "visible" }}
                  >
                    <div className="text pt-20">
                      {/* <p>
                          THE ULTIMATE BOOT CAMP IS REFRESHED! Developed by
                          Coach Ken Taylor and hosted by Will Brogan for over a
                          decade to commercial/fleet dealers, this hallmark
                          event has brought systematic ideas to rookies and
                          valuable best practice reminders to veterans.
                        </p>
                        <p>
                          Now a TWO-DAY PROGRAM, it is adapted to fit the unique
                          challenges of this transitional era!
                        </p> */}
                      <span className="sub-title mb-15">
                        Jump to Specific Event:
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {loading?
                <PreLoader/>:EventListData?.length > 0 &&
                  EventListData?.map((data, index) => {
                    const isActive = data.active;
                    const backgroundColor = colors[index % colors?.length];
                    const cursor = isActive ? "pointer" : "not-allowed";
                    const opacity = isActive ? 1 : 0.3;
                    const fontColor = data.fontColor || 'white';

                    return (
                      <div key={index} className="col-xl-3 col-lg-4 col-md-6">
                        <div
                          className="service-item style-two wow animated card-background-image"
                          style={{
                            backgroundColor: data.color || backgroundColor,
                            cursor,
                            opacity,
                            visibility: "visible",
                            color: fontColor,
                          }}
                        >
                          <h5 className="cursor-off" style={{ color: fontColor }}></h5>
                          <a
                            className="services-link"
                            href={`/event/${data.name}/${data.id}`}
                            style={{ pointerEvents: isActive ? "auto" : "none", color: fontColor }}
                          >
                            {data.name.toUpperCase()}
                          </a>
                          <p className="services-time" style={{ color: fontColor }}>
                            {data.time.trim()}
                          </p>
                          <div className="mb-3">
                            <img src={data.logo} height="70" alt={data.name} />
                          </div>
                          <a
                            className="read-more style-two cursor-off"
                            href={`/event/${data.name}/${data.id}`}
                            style={{ pointerEvents: isActive ? "auto" : "none", color: fontColor }}
                          >
                            <span>Read More</span>
                            <i className="far fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>


            </div>
          </div>
        )}
                        {/* Video Area start */}
                        {/* <div className="video-area-two rel z-1 rpt-70 pt-70">
                            <div className="container">
                                <div className="section-title mb-40 wow fadeInUp delay-0-2s">
                                    <span className="sub-title mb-15">Testimonial</span>
                                    <h2>2024 Atlanta Ultimate Boot Camp </h2>
                                </div>
                                <div className="video-part style-two mb-40 wow fadeInUp delay-0-2s">
                                    <img src="assets/images/video/video-bg-five.png" alt="Video" />
                                    <a href="https://www.youtube.com/watch?v=UygX_tzdW-k"
                                        className="mfp-iframe video-play">
                                        <i className="fas fa-play" />
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        {/* Video Area end */}

                    </div>
                </section>
                {/* Service Style Two end */}
            </div>
        </>
    );
};

export default Confirmation;
