export const componentNames = {
  dashboard: "dashboard",
  admin_role: "admin-role",
  admin_permission: "admin-permission",
  invoice: "invoice",
  admin_user: "admin-user",
  menu: "menu",
  admin_management: "admin-management",
  admin_health: "admin-health",
  admin_details_health: "admin-health-details",
  img_resize: "resize-image",
  admin_package: "admin-package",
  admin_time_slot: "admin-time-slot",
  doctor: "admin-doctor",
  doctorSupport: "admin-doctor-support",
  settings: "admin-settings",
  settings_main: "admin-settings_main",
  faq: "admin-faqs",
  appointment: "admin-appointment",
  newcase: "admin-new-case",
  notification: "notification",
  privacy: "admin-privacy",
  terms: "admin-terms",
  live_event: "admin-live-event",
  slider: "admin-slider",
  notificationUser: "user-notification",
  admin_package_management: "Subscription-package",
  subscription: "subscription",
  assessment: "assessment",
  formAppointment: "formAppointment",
  feedback: "feedback",
  payment: "payment",
  therapy: "therapy",
  changePassword: "change-password",
  offers: "offers",
  device: "device",
  device_management: "device-management",
  updateProfile: "update-profile",
  editProfile: "edit-profile",
  customer: "customer",
  help: "help",
  holiday: "holiday",
  order: "order",
  deviceHistory: "deviceHistory",
  deviceRequest: "deviceRequest",
  deviceReplace: "deviceReplace",
  medicine: "medicine",
  teamManagement: "teamManagement",
  inquiry: "inquiry",
  accountgroup: "accountgroup",
  account: "account",
  account_main: "accountMain",
  accountname: "accountname",
  transaction: "transaction",
  finance: "finance",
  patientdiscontinue: "patientdiscontinue",
  report: "report",
  appointmentReport: "appointmentReport",
  revanueReport: "revanueReport",
  subscriptionReport: "subscriptionReport",
  appointmentCityReport: "appointmentCityReport",
  followUpReport: "followUpReport",
  feedbackReport: "feedbackReport",
  treatmentReport: "treatmentReport",
  drSessionReport: "drSessionReport",
  deviceReport: "deviceReport",
  deviceReturn: "deviceReturn",
  painReport: "painReport",
  consultation: "consultation",
  consultationAdd: "AddConsultation",
  changeDoctor: "ChangeDoctor",
};
