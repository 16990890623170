import { postData, deleteData, getData } from "./base-api";
import ApiUris from "./apiUris";

export const StaffAPI = {
  createStaff(request) {
    return postData(ApiUris.staff.createStaff, request);
  },
  updateStaff(request) {
    return postData(ApiUris.staff.updateStaff, request);
  },

  getStaffList(request) {
    return postData(ApiUris.staff.getStaff, request);
  },
  getStaffListById(id) {
    return getData(ApiUris.staff.getStaffById + `${id}`);
  },
  deleteStaff(id) {
    return deleteData(`${ApiUris.staff.deleteStaff}?id=${id}`);
  },
};
