import { useEffect } from "react";
import Nav from "./Nav";
import LOGO from "../assets/images/logos/logo-2.png";
import { animation, sidebarClick, stickyNav } from "../utils";

const Header = ({ singleMenu }) => {
  useEffect(() => {
    stickyNav();
  }, []);

  useEffect(() => {
    animation();
    sidebarClick();
  }, []);
  return (
    <header className="main-header menu-absolute">
      {/*Header-Upper*/}
      <div className="header-upper">
        <div className="container container-1620 clearfix">
          <div className="header-inner rpy-10 rel d-flex align-items-center">
            <div className="logo-outer">
              <div className="logo">
                <a href="/">
                  <img
                    // src="../assets/images/logos/logo-2.png"
                    src={LOGO}
                    alt="Logo"
                    title="Logo"
                    width="400px"
                  />
                </a>
              </div>
            </div>
            <div className="nav-outer ms-xl-auto clearfix">
              {/* Main Menu */}
              <Nav singleMenu={singleMenu} />
              {/* Main Menu End*/}
            </div>
          </div>
        </div>
      </div>
      {/*End Header Upper*/}
    </header>
  );
};
export default Header;
