import { Accordion, AccordionCollapse } from "react-bootstrap";
import Menu from "./Menu";
import { useState } from "react";
import logo from "../assets/images/logos/logo-2.png";

const Nav = ({ singleMenu }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="main-menu navbar-expand-md">
      <Accordion>
        <div className="navbar-header py-10">
          <div className="mobile-logo">
            <a href="/">
              <img src={logo} alt="Logo" title="Logo" width="300" />
            </a>
          </div>
          {/* Toggle Button */}
          <Accordion
            as={"button"}
            onClick={() => setToggle(!toggle)}
            className="navbar-toggle"
            eventKey="navbar-collapse"
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </Accordion>
        </div>
        <AccordionCollapse
          eventKey="navbar-collapse"
          className={
            toggle
              ? "d-block navbar-collapse clearfix"
              : "navbar-collapse clearfix"
          }
        >
          <Menu singleMenu={singleMenu} />
        </AccordionCollapse>
      </Accordion>
    </nav>
  );
};

export default Nav;
