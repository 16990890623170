import React, { useState, createContext, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  localStorageItemNames,
  ACCOUNT_CREATE_STATUS,
  ACCOUNT_UPDATE_STATUS,
  componentRoutes,
  messageTypes,
} from "../constants";
import { AccountApi } from "../api";
import { NotificationContext } from "./notification-context";
import { useLoader } from "./loader-context";


/**
 * Authentication context
 */
export const AuthContext = createContext();

/**
 * Authentication data provider
 */
export const AuthProvider = ({ children }) => {
  /**
   * use notification context
   */
  const { addMessage, addServerErrors } = useContext(NotificationContext);
  const { showLoading } = useLoader();
  const url = window.location.pathname;
  /**
   * use history hooks
   */
  const history = useNavigate();

  /**
   * user data state management
   */
  const [user, setUser] = useState();

  /**
   * logged in state
   */
  // const [loggedIn, setLoggedIn] = useState(
  //   localStorage.getItem(localStorageItemNames.user_token) ? true : false
  // );
  const [loggedIn, setLoggedIn] = useState(false);

  /**
   * handle api response for all the api calls
   * @param {*} response
   * @returns
   */
  const handleResponse = (response) => {
    if (response?.error?.response?.data?.ex?.errors) {
      addServerErrors(response?.error?.response?.data?.ex?.errors);
    }

    return response?.error?.response
      ? response.error.response.data
      : response?.data;
  };

  /**
   * sign up/create new user
   * @param {*} request
   */
  const Register = async (request) => {
    const response = await AccountApi.Register({
      ...request,
      raiseError: true,
    });
    if (response.status === 200) {
      history(`/membersArea/login/${1}`)
    }
    // const newResponse = handleResponse(response);
    // if (!newResponse.success && newResponse.status < 200) {
    //   if (newResponse.status === 3) {
    //     addMessage({
    //       message: (
    //         <div>
    //           {ACCOUNT_CREATE_STATUS[newResponse.status]}
    //           <a
    //             onClick={() => history(componentRoutes.home)}
    //             style={{
    //               marginLeft: 4,
    //               marginRight: 4,
    //               color: "#eee",
    //               fontWeight: "bold",
    //               cursor: "pointer",
    //             }}
    //           >
    //             Register
    //           </a>
    //         </div>
    //       ),
    //     });
    //   } else {
    //     addMessage({ message: ACCOUNT_CREATE_STATUS[newResponse.status] });
    //     history(`/membersArea/login/${1}`)
    //   }
    // }
    // return newResponse;
  };

  /**
   * login user
   * @param {*} param0
   * @returns
   */
  const onLoggedIn = async ({ email, password }) => {

    // const response = await AccountApi.login({
    //   email: email,
    //   password,

    // });
    // console.log('response', response)

    // if (response?.error) {
    //   addMessage({ message: response.error.response.error, type: "error" });
    //   // return response.error.response.data;
    // }

    // if (response?.data?.data) {
    //   localStorage.setItem(
    //     localStorageItemNames.user_token,
    //     response?.data?.data?.token
    //   );
    //   localStorage.setItem(
    //     localStorageItemNames.userID,
    //     response?.data?.data?.userId
    //   );
    //   addMessage({ message: response?.data?.msg, type: "success" });

    // } else {
    //   addMessage({ message: "User logged in failed." });

    // }

    setLoggedIn(true);
    history("/")
    // return response.data;
  };

  /**
   * logout user
   */
  const onLogout = async () => {
    // const response = await AccountApi.logout();

    // if (response) {
    //   localStorage.removeItem(localStorageItemNames.user_token);
    //   // setLoggedIn(false);
    //   setUser(null);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 300);
    // }
    setLoggedIn(false);
    history("/")
  };

  /**
   * verify user details
   * @param {*} param0
   * @returns
   */
  const verify = async ({ code }) => {
    const response = await AccountApi.verify({
      code,
    });

    return handleResponse(response);
  };

  /**
   * initialize the recovery init for user
   * @param {*} param0
   * @returns
   */
  const recoveryInit = async ({ email }) => {
    const response = await AccountApi.recoveryInit({
      contactText: email,
      userContactType: 1,
      raiseError: true,
    });

    return handleResponse(response);
  };

  /**
   * recovery open for user
   * @param {*} param0
   * @returns
   */
  const recoveryOpen = async ({ code }) => {
    const response = await AccountApi.recoveryOpen({
      code,
    });

    return handleResponse(response);
  };

  /**
   * recovery change for user
   * @param {*} param0
   * @returns
   */
  const recoveryChange = async ({ code, newPassword }) => {
    const response = await AccountApi.recoveryChange({
      code,
      newPassword,
    });

    return handleResponse(response);
  };

  /**
   * update user information
   * @param {*} request
   * @returns
   */
  const updateUser = async (request) => {
    const response = await AccountApi.updateUser(request);
    const newResponse = handleResponse(response);
    if (newResponse.success) {
      addMessage({
        message: ACCOUNT_UPDATE_STATUS[1],
        type: messageTypes.success,
      });
      return true;
    } else if (!newResponse.errors) {
      addMessage({ message: ACCOUNT_UPDATE_STATUS[newResponse.status] });
    }
  };

  /**
   * update user password
   * @param {*} request
   * @returns
   */
  const updatePassword = async (request) => {
    const response = await AccountApi.updatePassword(request);
    return handleResponse(response);
  };

  /**
   * User forgot  password
   * @param {*} request
   * @returns
   */
  const forgotPassword = async (request) => {
    const response = await AccountApi.forgotPassword(request);
    return handleResponse(response);
  };
  const resetPassword = async (request) => {
    const response = await AccountApi.resetPassword(request);
    addMessage({ message: ACCOUNT_CREATE_STATUS[response.status] });
    return handleResponse(response);
  };
  const changeUserPassword = async (request) => {
    const response = await AccountApi.changePassword(request);
    addMessage({ message: ACCOUNT_CREATE_STATUS[response.status] });
    return handleResponse(response);
  };
  /**
   * get user details
   */
  const getUser = async () => {
    let userId = localStorage.getItem(localStorageItemNames.userID);
    const response = await AccountApi.getUser({ _id: userId });
    if (response && response.data) {
      // const { data } = response.data;
      setUser(response?.data?.data);
    } else {
      setUser(null);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem(localStorageItemNames.user_token);
    if (!user && (loggedIn || userId)) {
      // getUser();
      if (url === "/login") {
        history(componentRoutes.dashboard);
      } else {
        history(url);
      }
    }
  }, [loggedIn]);

  const contextValue = {
    loggedIn,
    user,
    state: (userData) => setUser(userData),
    onLoggedIn,
    onLogout,
    verify,
    updatePassword,
    updateUser,
    Register,
    forgotPassword,
    resetPassword,
    changeUserPassword,
    recovery: {
      init: recoveryInit,
      open: recoveryOpen,
      change: recoveryChange,
    },
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
