export function transformString(inputString) {
  return inputString;
  // let finalString = "";
  // if (inputString === inputString.toUpperCase()) {
  //   finalString = inputString;
  // } else {
  //   let spacedString = inputString.replace(/([A-Z])/g, " $1").trim();
  //   finalString = spacedString
  //     .split(" ")
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // }
  // return finalString;
}
