import { getData, postData, putData } from "./base-api";
import ApiUris from "./apiUris";

/**
 * Account Api method declarations
 */
export const AccountApi = {
  /**
   * get user
   * @returns
   */
  getUser(request) {
    return getData(
      ApiUris.user.getUser + `${request?._id ? `?_id=${request?._id}` : ""}`
    );
  },

  /**
   * create user
   * @param {*} request
   * @returns
   */
  Register(request) {
    return postData(ApiUris.authUser.Register, request);
  },

  /**
   * verify user
   * @param {*} request
   * @returns
   */
  verify(request) {
    return postData(ApiUris.authenticate.verifyUser, request);
  },

  /**
   * user recovery init
   * @param {*} request
   * @returns
   */
  recoveryInit(request) {
    return postData(ApiUris.authenticate.passwordRecoveryInitialize, request);
  },

  /**
   * recovery open for user
   * @param {*} request
   * @returns
   */
  recoveryOpen(request) {
    return postData(ApiUris.authenticate.passwordRecoveryOpen, request);
  },

  /**
   * recovery change
   * @param {*} request
   * @returns
   */
  recoveryChange(request) {
    return postData(ApiUris.authenticate.passwordRecoveryChange, request);
  },

  /**
   * update user information
   * @param {*} request
   * @returns
   */
  updateUser(request) {
    return postData(ApiUris.authenticate.updateUser, request);
  },

  /**
   * update user password
   * @param {*} request
   * @returns
   */
  updatePassword(request) {
    return postData(ApiUris.authenticate.updatePassword, request);
  },

  /**
   * User forgot password
   * @param {*} request
   * @returns
   */
  forgotPassword(request) {
    return postData(ApiUris.authenticate.forgotPassword, request);
  },
  resetPassword(request) {
    return putData(ApiUris.authenticate.resetPassword, request);
  },
  changePassword(request) {
    return putData(ApiUris.authenticate.changePassword, request);
  },

  /**
   * login user using email and password
   * @param {*} request
   * @returns
   */
  login(request) {
    return postData(ApiUris.authUser.loginUser, request);
  },


  /**
   * logout user
   * @returns
   */
  logout() {
    return postData(ApiUris.authenticate.logout);
  },
};
