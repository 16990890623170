import { postData, deleteData, getData } from "./base-api";
import ApiUris from "./apiUris";

export const EventDataAPI = {
    createEventData(request) {
        return postData(ApiUris.EventData.createEventData, request);
    },
    updateEventData(request) {
        return postData(ApiUris.EventData.updateEventData, request);
    },

    getEventDataList(request) {
        return postData(ApiUris.EventData.getEventData, request);
    },
    getEventDataListById(id) {
        return getData(`${ApiUris.EventData.getEventDataById}${id}`);
    },
    deleteEventData(id) {
        return deleteData(`${ApiUris.EventData.deleteEventData}?id=${id}`);
    },
    uploadImage(request) {
        return postData(ApiUris.EventData.uploadImage, request)
    }
};
