import React, { useState, useCallback, createContext, useContext } from "react";
import { executeApi } from "./base-context";
import { LoaderContext } from "./loader-context";
import { NotificationContext } from "./notification-context";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useLoader } from "../hooks";
import { HomeAPI } from "../api";


export const HomeDataContext = createContext({});

export const HomeDataProvider = ({ children }) => {
    const navigation = useNavigate();
    const [loading, setLoading] = useState();
    const { addMessage } = useContext(NotificationContext);
    const [HomeListData, setHomeListData] = useState([]);
    const executeHomeDataAPI = async (apiFunction, request) => {
        const response = await executeApi(
            apiFunction,
            request,
            // showLoading,
            addMessage
        );
        return response;
    };

    const getHomeDataList = async (request) => {
        try {
            setLoading(true);
            const res = await executeHomeDataAPI(
                HomeAPI.getHomeDataList,
                request
            );
            if (res.succeeded) {

                setHomeListData(res.data);
            } else {
                addMessage("data get Failed");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            addMessage("Failed to fetch Data");
        }
    };
    const getHomeDataListById = async (request) => {
        console.log("request", request);
        try {
            setLoading(true);
            const res = await executeHomeDataAPI(
                HomeAPI.getEventDataListById,
                request
            );
            if (res.succeeded) {
                setHomeListData(res.data);
                setLoading(false);
            } else {
                setLoading(false);
                addMessage("data get Failed");
            }
        } catch (error) {
            setLoading(false);
            addMessage("Failed to fetch Data");
        }
    };






    const contextValue = {
        HomeListData,
        loading,
        getHomeDataList: useCallback((request) => {
            getHomeDataList(request);
        }, []),
        getHomeDataListById: useCallback((request) => {
            getHomeDataListById(request);
        }, []),
    };

    return (
        <HomeDataContext.Provider value={contextValue}>
            {children}
        </HomeDataContext.Provider>
    );
};

export const HomeData = () => useContext(HomeDataContext);
