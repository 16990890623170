import { Link } from "react-router-dom";
import LOGO from "../assets/images/logos/logo-2.png";
const Footer = () => {
  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
  };
  return (
    <footer className="main-footer hero-area footer-five pt-50">
      <div className="container container-1290 pb-40">
        <div className="footer-top pt-0 pb-20">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="footer-logo mb-20 wow fadeInRight delay-0-2s animated">
                <a href="/">
                  <a>
                    <img src={LOGO} alt="Logo" />
                  </a>
                </a>
              </div>
            </div>
            <div className="col-lg-8 text-lg-end">
              <div className="social-style-four mb-20 wow fadeInLeft delay-0-2s animated ">
                <a href="https://www.facebook.com/CommercialTruckTraining/">
                  <i className="fab fa-facebook-f" /> <span>Facebook</span>
                </a>
                <a href="https://www.youtube.com/channel/UCLGeCxEYgIh-yQcW1l1Kocw">
                  <i className="fab fa-youtube" /> <span>YouTube</span>
                </a>
                <a href="https://www.linkedin.com/company/commercial-truck-training">
                  <i className="fab fa-linkedin" /> <span>Linkedin</span>
                </a>
                <a href="https://www.instagram.com/commercialtrucktraining/">
                  <i className="fab fa-instagram" /> <span>Instagram</span>
                </a>
                <a href="https://www.tiktok.com/@kentaylorctt">
                  <i class="fab fa-tiktok"></i> <span>Tiktok</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-between pt-70">
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div className="footer-widget footer-info wow fadeInUp delay-0-4s">
              <h5 className="mb-3">Get In Touch</h5>
              <ul className="list-style-two">
                <li>
                  <i className="far fa-phone-alt"></i>{" "}
                  <a href="tel:9043738190">904.373.8190</a>
                </li>
                <li>
                  <i className="far fa-envelope" />{" "}
                  <a
                    href="mailto:will@commercialtrucktraining.com"
                    className="break-all"
                  >
                    will@commercialtrucktraining.com
                  </a>
                </li>
                <li>
                  <i className="far fa-clock" />{" "}
                  <span>Monday - Friday, 9am - 6pm</span>
                </li>
                <li>
                  <i className="far fa-map-marker-alt"></i>{" "}
                  <a
                    href="https://maps.app.goo.gl/j2TYDg83woYvArX46"
                    target="_blank"
                  >
                    100 West Big Beaver Road, Suite 200, Troy MI 48084
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="footer-widget footer-info wow fadeInUp delay-0-4s">
              <h5 className="mb-3">Our Services</h5>
              <ul className="list-style-two">
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/service/consulting/18">Dealership Consulting</Link>
                </li>
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/service/ultimateBootCamp/19">
                    Ultimate Boot Camp
                  </Link>
                </li>
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/service/championsClub/24">Coaching Calls</Link>
                </li>
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/service/focusedFridays/27">Focused Fridays</Link>
                </li>
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/service/marketingServices/28">
                    Marketing Services
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div className="footer-widget footer-info wow fadeInUp delay-0-4s">
              <h5 className="mb-3">Free Content</h5>
              <ul className="list-style-two">
                {/* <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <a  href="contact">Contact Us</a>
                </li> */}
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/freeContents/35ProspectingIdeas/33">
                    35 Prospecting Ideas
                  </Link>
                </li>
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/freeContents/dailyManagerChecklist/34">
                    Daily Checklist for Commercial Managers
                  </Link>
                </li>
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/freeContents/employeePurchaseProgram/35">
                    Employee Purchase Program
                  </Link>
                </li>
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/freeContents/ifItWereMe/36">If It Were Me</Link>
                </li>
                <li>
                  <i className="far fa-long-arrow-right"></i>{" "}
                  <Link to="/freeContents/quickQuestionSkillGuide/37">
                    Quick Guide to Questioning Skills
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area pt-25 pb-15">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div className="footer-copyright-text wow fadeInUp delay-0-2s ">
                <p className="text-center">
                  Copyright &#169; {getYear()}&nbsp;
                  <span style={{ color: "#e8e60a" }}>
                    Commercial Truck Training
                  </span>
                  , All Right Reserved. Design by
                  <a target="_blank" href="http://exultitsolution.com" rel="noreferrer">
                    <span style={{ color: "#5d95df" }}> Exult IT Solution</span>
                    .
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
