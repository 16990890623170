import React, { useState, useCallback, createContext, useContext } from "react";
import { executeApi } from "./base-context";
import { NotificationContext } from "./notification-context";
import { FreeContentAPI } from "../api";

export const FreeContentContext = createContext({});

export const FreeContentProvider = ({ children }) => {
  const [loading, setLoading] = useState();
  const { addMessage } = useContext(NotificationContext);
  const [listData, setListData] = useState([]);
  const executeStaffAPI = async (apiFunction, request) => {
    const response = await executeApi(
      apiFunction,
      request,
      // showLoading,
      addMessage
    );
    return response;
  };

  const getContentList = async (request) => {
    try {
      setLoading(true);
      const res = await executeStaffAPI(FreeContentAPI.getFreeListUrl, request);
      if (res.succeeded) {
        setListData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        addMessage("data get Failed");
      }
    } catch (error) {
      setLoading(false);
      addMessage("Failed to fetch Data");
    }
  };

  const contextValue = {
    listData,
    loading,
    getContentList: useCallback((request) => {
      getContentList(request);
    }, []),
  };

  return (
    <FreeContentContext.Provider value={contextValue}>
      {children}
    </FreeContentContext.Provider>
  );
};
