import { animation } from "./utils";
import ScrollTop from "./ScrollTop";
import { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PreLoader from "./component/preLoader";
import Header from "./component/header";
import Footer from "./component/footer";
import { AppContextWrapper } from "./layout/app-context-wrapper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/style.sass";
import "./App.css";
import ServiceSub from "./pages/ServiceSub";
import E404 from "./pages/404";
import Register from "./pages/Register";
import Checkout from "./pages/Checkout";
import Confirmation from "./pages/Confirmation";
// import Login from "./pages/Login";
const Home = lazy(() => import("./pages/Home"));
const Staff = lazy(() => import("./pages/Staff"));
const Service = lazy(() => import("./pages/Service"));
const FreeContent = lazy(() => import("./pages/FreeContent"));
const KanaKas = lazy(() => import("./pages/Kanakas"));
const EventDetails = lazy(() => import("./pages/eventdetails"));
const Login = lazy(() => import("./pages/Login"));
const LoginFreeContent = lazy(() => import("./pages/LoginFreeContent"));

const App = () => {
  useEffect(() => {
    animation();
  }, []);
  return (
    <AppContextWrapper>
      <Header />
      <div className="mainApp">
        <Suspense fallback={<PreLoader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<E404 />} />
            <Route path="/ultimate-boot-camp-checkout" element={<Checkout />} />
            <Route
              path="/ultimate-boot-camp-confirmation"
              element={<Confirmation />}
            />
            <Route path="/staff/:name/:id" element={<Staff />} />
            <Route path="/services/:name/:id" element={<Service />} />
            <Route
              path="/services/:name/:id/:subName/:menuId"
              element={<ServiceSub />}
            />
            <Route path="/service/:name/:id" element={<Service />} />
            <Route
              path="/service/:name/:id/:subName/:menuId"
              element={<ServiceSub />}
            />
            <Route path="/freeContents/:name/:id" element={<FreeContent />} />
            <Route
              path="/freeContents/:name/:id/:subName/:menuId"
              element={<FreeContent />}
            />
            <Route path="/freeContent/:name/:id" element={<FreeContent />} />
            <Route
              path="/freeContent/:name/:id/:subName/:menuId"
              element={<FreeContent />}
            />
            <Route path="/free-Content/:name/:id" element={<FreeContent />} />
            <Route
              path="/free-Content/:name/:id/:subName/:menuId"
              element={<FreeContent />}
            />
            <Route path="/event/:name/:id" element={<KanaKas />} />
            <Route
              path="/eventDetails/:details/:id"
              element={<EventDetails />}
            />
            <Route path="/404" element={<E404 />} />
            <Route path="/membersArea/login/:id" element={<Login />} />
            <Route path="/members-Area/login/:id" element={<Login />} />
            <Route path="membersArea/register/:id" element={<Register />} />
            <Route path="members-Area/register/:id" element={<Register />} />
            <Route
              path="membersArea/:name/:id"
              element={<LoginFreeContent />}
            />
          </Routes>
        </Suspense>
      </div>
      <Footer />
      <ScrollTop />
    </AppContextWrapper>
  );
};

export default App;
