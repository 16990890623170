import React, { useState, useCallback, createContext, useContext } from "react";
import { executeApi } from "./base-context";
import { LoaderContext } from "./loader-context";
import { NotificationContext } from "./notification-context";
import { ServiceAPI } from "../api";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useLoader } from "../hooks";

export const ServiceContext = createContext({});

export const ServiceProvider = ({ children }) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState();
  const { addMessage } = useContext(NotificationContext);
  const [listData, setListData] = useState([]);
  const executeServiceAPI = async (apiFunction, request) => {
    const response = await executeApi(
      apiFunction,
      request,
      // showLoading,
      addMessage
    );
    return response;
  };

  const getServiceList = async (request) => {
    try {
      setLoading(true);
      const res = await executeServiceAPI(ServiceAPI.getServiceList, request);
      if (res.succeeded) {
        setListData(res.data);
      } else {
        setLoading(false);
        addMessage("data get Failed");
      }
    } catch (error) {
      setLoading(false);
      addMessage("Failed to fetch Data");
    }
  };
  const getServiceListById = async (request) => {
    console.log("request", request);
    try {
      setLoading(true);
      const res = await executeServiceAPI(
        ServiceAPI.getServiceListById,
        request
      );
      if (res.succeeded) {
        setListData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        addMessage("data get Failed");
      }
    } catch (error) {
      setLoading(false);
      addMessage("Failed to fetch Data");
    }
  };
  const createServiceRecord = async (request) => {
    try {
      const response = await executeServiceAPI(
        ServiceAPI.createService,
        request
      );

      if (response?.succeeded) {
        toast.success("Service Add successfully");
        addMessage({ message: "Service Add successfully", type: "success" });
        navigation("/Service");
      } else {
        addMessage({
          message: response?.msg || "Service Add unsuccessfully",
          type: "error",
        });
        toast.error(response?.msg || "Service Add unsuccessfully");
      }
    } catch (error) {
      addMessage({ message: error?.msg, type: "error" });
    }
  };

  const updateServiceRecord = async (request) => {
    try {
      const response = await executeServiceAPI(
        ServiceAPI.updateService,
        request
      );

      if (response?.succeeded) {
        toast.success("Service Update Successfully");

        addMessage({ message: "Service Update Successfully", type: "success" });

        navigation("/Service");
      } else {
        addMessage({ message: response?.message, type: "error" });
      }
    } catch (error) {
      addMessage({ message: "Service update unsuccessfully", type: "error" });
      toast.error("Service update unsuccessfully");
    }
  };

  const removeServiceRecord = async (id) => {
    try {
      const response = await executeServiceAPI(ServiceAPI.deleteService, id);
      if (response?.succeeded) {
        toast.success("Service delete successfully");
        addMessage({ message: "Service delete successfully", type: "success" });
        let payload = {
          limit: "100",
          offset: "0",

          sorting: {
            fieldName: "id",
            direction: "Asc",
          },
        };
        await getServiceList(payload);
      }
    } catch (error) {
      toast.error("Service delete unsuccessfully");
      addMessage({ message: "Service delete unsuccessfully", type: "error" });
    }
  };

  const contextValue = {
    listData,
    loading,
    getServiceList: useCallback((request) => {
      getServiceList(request);
    }, []),
    getServiceListById: useCallback((request) => {
      getServiceListById(request);
    }, []),
    createServiceRecord: useCallback((request) => {
      createServiceRecord(request);
    }, []),
    updateServiceRecord: useCallback((request) => {
      updateServiceRecord(request);
    }, []),
    removeServiceRecord: useCallback((request) => {
      removeServiceRecord(request);
    }, []),
  };

  return (
    <ServiceContext.Provider value={contextValue}>
      {children}
    </ServiceContext.Provider>
  );
};

export const Service = () => useContext(ServiceContext);
